/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react';

export default function DesktopLayoutWrapper(
    props: React.PropsWithChildren<{
        contentClassName?: string;
        contentStyle?: React.CSSProperties;
        wrapperClassName?: string;
        wrapperStyle?: React.CSSProperties;
    }>,
) {
    return (
        <div
            className={`flex relative padding-15 width-100 justify-center ${props.wrapperClassName ?? ''}`}
            style={props.wrapperStyle}
        >
            <div
                className={`gap gap-20 width-100 ${props.contentClassName ?? ''}`}
                style={{
                    maxWidth: 'calc(1280px)',
                    minHeight: 'calc(100vh - 116px - 80px)', // 100vh - wireframe header height - footer slim height
                    minWidth: 'calc(1024px - 20px)',
                    ...props.contentStyle,
                }}
            >
                {props.children}
            </div>
        </div>
    );
}
