import type { DynamicPageProps } from '@/core/features/a-dynamic-page/dynamic-page-types';

import PageComponentSwitchWrapperDesktop from '@/features/a-dynamic-page/components/page-component-switch-wrapper-desktop';
import DesktopContentWrapper from '@/features/page-layouts/desktop/desktop-content-wrapper';
import DesktopLayoutWrapper from '@/features/page-layouts/desktop/desktop-layout-wrapper';

export default function DesktopS0Layout(props: DynamicPageProps) {
    const components = props.components;

    const headerTopComponents = components.filter((components) => components.meta.layoutPosition === 'header_top');
    const fullColComponents = components.filter((component) => component.meta.layoutPosition === 'full');
    const footerComponents = components.filter((component) => component.meta.layoutPosition === 'footer');

    if (!fullColComponents.length) {
        return null;
    }

    return (
        <div className={'bg-white'}>
            {!!headerTopComponents.length && (
                <div className={'flex-center-vertical'}>
                    <PageComponentSwitchWrapperDesktop
                        {...props}
                        components={headerTopComponents}
                    />
                </div>
            )}
            <DesktopLayoutWrapper>
                <DesktopContentWrapper isPageLoading={props.isPageLoading}>
                    <PageComponentSwitchWrapperDesktop
                        {...props}
                        components={fullColComponents}
                    />
                </DesktopContentWrapper>
            </DesktopLayoutWrapper>
            {!!footerComponents.length && (
                <div className={'flex-center-vertical'}>
                    <PageComponentSwitchWrapperDesktop
                        {...props}
                        components={footerComponents}
                    />
                </div>
            )}
        </div>
    );
}
